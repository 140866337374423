import React from 'react';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>...ENTERPRISE SOLUTIONS...</h2>
      </header>

    </article>
 <section className="wrapper style5">
      <div className="inner">
  
        <h5>VMware ON AWS</h5>
         <strong>Accelerate Your App and Cloud Transformation</strong>
            <p>
              Deliver modern apps at the speed your business demands and operate across the data center, the edge, and any cloud with VMware Cloud.
            </p>
        <p>
          VMware Cloud on AWS brings VMware’s enterprise-class Software-Defined Data Center software to the AWS Cloud, and enables customers to run production applications across
          VMware vSphere®-based private, public, and hybrid cloud environments, with optimized access to AWS services.
        </p>
        <hr />
      <h3>CLOUD MIGRATION</h3>
            <strong>Seamless App Migration to Any Cloud</strong>
            <p>
              Take the fastest, lowest cost path to the cloud and accelerate application modernization with VMware Cloud migration solutions.
        </p>
        <hr />
           <h3>APP DEVELOPMENT</h3>
            <strong>Making the web safe for every user, anywhere</strong>
            <p>
              Technologies are evolving faster. To survive the competition,
              your applications needs to be built using a future ready technology.
            </p>
        <hr />
            <h3>END POINT DETECTION & RESPONSE</h3>
            <p>
              See more by connecting email, endpoints, servers, and cloud workloads with extended detection and response (XDR). Gain a broader perspective, better context, and the power
              to investigate and respond from a single place with an attack-centric view of an entire chain of events.
            </p>
        <hr />
        <h3>NETWORK & ENTERPRISE MONITORING</h3>
            <p>
              Enterprise network monitoring solution which monitors the health,
             availability, and performance of your network in real time. 
            </p>
        <hr />
         <h3>EMAIL SERVICES</h3>
            <p>
              Proactively secure the web with advanced, real-time threat defenses—full content
              inspection and in-line security scanning help mitigate risk and protect against malware.
            </p>
        <hr />
        <h3>SECURITY SOLUTIONS</h3>
            <p>
              Security in every layer of a hybrid cloud environment to help accelerate innovation and
              securely build, modernize or migrate mission critical workloads.
            </p>
        <hr />
         <h3>CYBER SECURITY</h3>
            <p>
              Application of technologies, processes and controls to protect systems,
              networks, programs, devices and data from cyber attacks.
            </p>
        <hr />

      </div>

    </section>
  </Layout>
);

export default IndexPage;